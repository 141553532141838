import React, { useState } from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const featuresJson = props.data.allFeaturesJson.edges;
  const technologiesJson = props.data.allTechnologiesJson.edges;

  const [active, setActive] = useState('All');

  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Gateway Apps - World class software development in a small-town package."
        />
      </Helmet>

      <div className="intro pb-5">
        <div className="container">
          <h1>Gateway Apps</h1>
          <p>Custom software and website development</p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Our Services</h2>
          </div>
          {markdown.map((edge) => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <Link className="service-link" to={edge.node.frontmatter.path}>
                <div className="card service service-teaser">
                  <div className="card-content">
                    <h2>{edge.node.frontmatter.title}</h2>
                    <p>{edge.node.excerpt}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <span className="title-3 text-dark mb-3">Technologies</span>
            {renderButtons(technologiesJson, active, setActive)}
          </div>
          {technologiesJson
            .map((edge) => (
              <div key={edge.node.id} className="col-6 col-md-4 col-lg-2 mb-2 mt-2">
                <a href={edge.node.url} target="_blank" rel="noreferrer">
                  <div
                    className={`technology ${
                      active === 'All' || edge.node.type.includes(active)
                        ? ''
                        : 'techNotSelected'
                    }`}
                    style={{ backgroundColor: stringToHslColor(edge.node.title, 50, 50) }}
                    title={edge.node.title}
                  >
                    {edge.node.image && (
                      <div className="technology-image">
                        <img src={withPrefix(edge.node.image)} alt={`${edge.node.title} Logo`} loading="lazy" />
                      </div>
                    )}
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Our Features</h2>
          </div>
          {featuresJson.map((edge) => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.image)} alt={`${edge.node.title} Image`} loading="lazy" />
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

const renderButtons = (technologiesJson, active, setActive) => {
  let technologyTypes = technologiesJson.flatMap((edge) => edge.node.type);

  // Remove duplicates and sort alphabetically
  technologyTypes = [...new Set(technologyTypes)].sort();

  const techButtons = technologyTypes.map((type) => (
    <div key={type} className="ml-3 d-inline-block">
      <button
        className={`technologyButton mb-1 ${active === type ? 'active' : ''}`}
        onClick={() => setActive(type)}
      >
        {type}
      </button>
    </div>
  ));

  // Add 'All' button at the beginning
  techButtons.unshift(
    <div key="All" className="ml-3 d-inline-block">
      <button
        className={`technologyButton ${active === 'All' ? 'active' : ''}`}
        onClick={() => setActive('All')}
      >
        All
      </button>
    </div>
  );

  return techButtons;
};

const stringToHslColor = (techName, saturation, lightness) => {
  let hash = 0;

  for (let i = 0; i < techName.length; i++) {
    hash = techName.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    allTechnologiesJson {
      edges {
        node {
          id
          title
          description
          image
          type
          url
        }
      }
    }
  }
`;

export default Home;
