import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import SEO from '../../components/SEO'
import Layout from '../../layouts/index'

const Team = (props) => {
  const teams = props.data.allMarkdownRemark.edges
  return (
    <Layout bodyClass="page-teams">
      <SEO title="Team" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Meet The Team</h1>
              <p>
                Our team of first-class developers are among the best in the nation and are sought out for projects from coast to coast.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row d-flex align-items-stretch">
          {teams.map((edge) => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="team card-two flex-grow-1">
                <div className="card-header">
                  <div className="card-header-left">
                    {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="img-fluid mb-2"
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.node.frontmatter.jobtitle}</strong>
                      </li>
                      {/* Grouping LinkedIn and GitHub icons with gap-2 */}
                      <li className="social-icons d-flex align-items-center">
                        {edge.node.frontmatter.linkedinurl && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={edge.node.frontmatter.linkedinurl}
                            aria-label={`${edge.node.frontmatter.title}'s LinkedIn`}
                            style={{ marginRight: '8px' }} // Adds right margin for spacing
                          >
                            <FontAwesomeIcon icon={faLinkedin} size="lg"/>
                          </a>
                        )}
                        {edge.node.frontmatter.githuburl && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={edge.node.frontmatter.githuburl}
                            aria-label={`${edge.node.frontmatter.title}'s GitHub`}
                          >
                            <FontAwesomeIcon icon={faGithub} size="lg"/>
                          </a>
                        )}
                      </li>
                      <li>
                        <a href={`mailto:${edge.node.frontmatter.email}`}>
                          {edge.node.frontmatter.email}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content" dangerouslySetInnerHTML={{ __html: edge.node.html }}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team/" } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
            linkedinurl
            githuburl
            email
          }
        }
      }
    }
  }
`

export default Team
